import React from 'react';
import { useTranslation } from "react-i18next";
import logo from './logo.png';
import './App.scss';

function App() {
  const { t } = useTranslation();

  return (
    <>
      <div id="content">
        <div id="content-left">
          <img src={logo} alt="madras logo"/>
        </div>
        <div id="content-right">
          <p>{t('maintenance.comingsoon')}</p>
          <p className="info">{t('maintenance.contactus')} <a href = "mailto: contact@madras-technologies.com">contact@madras-technologies.com</a></p>
        </div>
      </div>
      <footer>
        <p>© madras technologies {new Date().getFullYear()}</p>
      </footer>
    </>
  );
}

export default App;